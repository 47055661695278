//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "login",
  data() {
    return {
      email: "",
      password: "",
      emailRegex: /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/,
      passwordRegex:/^.{3,}$/,
      error: {
        email: "",
        password: "",
        api: "",
      },
      token: "",
    };
  },
  methods: {
    submit() {
      try {
        this.$auth
          .loginWith("local", {
            data: {
              email: this.email,
              password: this.password,
            },
          })
          .then((res) => {
            if (res.data.token) {
              this.$router.push("/");
            }
          })
          .catch((err) => {
            let temp = {};
            temp.api = err.response.data.message;
            this.error = temp;
          });
      } catch (error) {
        console.log("error", error);
      }
    },
    validate() {
      this.error = {
        email: "",
        password: "",
        api: "",
      };
      if (
        !this.emailRegex.test(this.email) ||
        !this.passwordRegex.test(this.password)
      ) {
        let temp = {};
        if (!this.emailRegex.test(this.email)) {
          temp.email = "Invalid email";
        }
        if (!this.passwordRegex.test(this.password)) {
          temp.password = "Invalid password";
        }
        this.error = temp;
      } else {
        this.submit();
      }
    },
  },
};
